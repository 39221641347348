import React, { useEffect } from 'react';

const UnderDevelopment = () => {
   
    return (
        <div>
            <h1 style={{textAlign:'center'}}>This page is under development. We will redirect to Albert's in 3s</h1>
        </div>
    );
};

export default UnderDevelopment;